import axios from "axios";
import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import { WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import { CSVLink } from "react-csv";
import Multiselect from 'multiselect-react-dropdown';

class Transaction extends React.Component {
    constructor() {
        super();
        this.state = {
            token: cookie.load("token"),
            userRole: cookie.load("userRole"),
            userGroup: cookie.load("userGroup"),
            walletBalance: cookie.load("walletBalance"),
            selectedTable: "Monthly",
            startDate: new Date(),
            endDate: new Date(),
            fromDate: this.makeShortDate(new Date()),
            toDate: this.makeShortDate(new Date()),
            transactions: [],
            filterValue: '',
            merchantEmail:'',
            walletHistory: [],
            dataByDate: {},
            isLoading: false,
            currentPage: 1,
            network: [
                { id: 1, name: "MTN" },
                { id: 2, name: "Glo" },
                { id: 3, name: "9mobile" },
                { id: 4, name: "Airtel" },
            ],
            filterOptions: [
                { id: 1, name: "Network" },
                // { id: 2, name: "MSISDN" },
                { id: 3, name: "Beneficiary No" },
                { id: 4, name: "Merchant ID" },
                { id: 5, name: "Customer Reference" }
            ],
            selectedFilters: [],
            marchantList:[],
            msisdn: "",
            beneficiaryPhone: "",
            customerReference: "",
            selectedNetwork: "",
            merchantId: "",
            status: "",

        };
        
        if (this.state.userGroup == "MERCHANT") {

            this.state.filterOptions = [
                { id: 1, name: "Network" },
                { id: 2, name: "MSISDN" },
                { id: 3, name: "Beneficiary No" },
                { id: 4, name: "Merchant ID" },
                { id: 5, name: "Customer Reference" },
                { id: 6, name: "Mobile App" }
            ]
        }
        this.setState({})
        //binding this to event-handler functions
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.getTransactions = this.getTransactions.bind(this);
        this.getDailyTransactions = this.getDailyTransactions.bind(this);
        this.getWeeklyTransactions = this.getWeeklyTransactions.bind(this);
        this.getCustomReport = this.getCustomReport.bind(this);
        this.getWalletBalance = this.getWalletBalance.bind(this);
        this.reduceTransactions = this.reduceTransactions.bind(this);
        this.onSelectFilter = this.onSelectFilter.bind(this);
        this.onRemoveFilter = this.onRemoveFilter.bind(this);
        this.makeShortDate = this.makeShortDate.bind(this);
        this.getMerchant = this.getMerchant.bind(this);
    }

    onSelectFilter(selectedList, selectedItem) {
        this.setState({
            selectedFilters: selectedList
        })
    }

    onRemoveFilter(selectedList, removedItem) {
        this.setState({
            selectedFilters: selectedList
        })
    }
    makeShortDate(datew) {
        datew = new Date(datew);
        let yy = datew.getFullYear();
        let mm = datew.getMonth();
        let dd = datew.getDate();
        let shortDate = `${yy}/${mm < 10 ? 0 : ""}${mm + 1}/${dd < 10 ? 0 : ""
            }${dd}`;
        return shortDate;
    }

    setStartDate(date) {
        let datew = this.makeShortDate(date);

        this.setState({
            startDate: date,
            fromDate: datew,
        });
    }

    setEndDate(date) {
        let datew = this.makeShortDate(date);

        this.setState({
            endDate: date,
            toDate: datew,
        });
    }

    getTransactions = () => {
        let api = "";
        if (this.state.userGroup == "MERCHANT") {
            api = "/merchant/merchant-monthly-report";
        } else {
            api = "/dealer/dealer-monthly-report";
        }
        axios
            .get(WEB_BASE_URL + api, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.description === "No data available") {

                } else {
                    const trans = res.data.data.data;
                    this.setState({ transactions: trans });
                }
            })
            .catch((err) => {
                toast.error("Error fetching transactions");
            });
    };

    getCustomReport() {
        this.setState({ isLoading: true });
    
        let api = this.state.userGroup === "MERCHANT"
            ? "/merchant/merchant-custom-report" 
            :  "/dealer/dealer-custom-report";
    
        let queryParams = new URLSearchParams();
    
        if (this.state.fromDate) {
            queryParams.append("startDate", this.state.fromDate.split('/').join('-'));
            queryParams.append("endDate", this.state.fromDate.split('/').join('-'));
        }
        if (this.state.status) queryParams.append("statusCode", this.state.status);
        if (this.state.beneficiaryPhone) queryParams.append("beneficiaryPhone", this.state.beneficiaryPhone);
        queryParams.append("offSet", "0");
        queryParams.append("page", "50000");
        if (this.state.customerReference) queryParams.append("customerRef", this.state.customerReference);
        if (this.state.msisdn) queryParams.append("msisdn", this.state.msisdn);
        if (this.state.selectedNetwork) queryParams.append("networkId", this.state.selectedNetwork);
        if (this.state.merchantId) queryParams.append("merchantId", this.state.merchantId);
        if (this.state.merchantEmail) queryParams.append("merchantId", this.state.merchantEmail);
    
        axios
            .get(WEB_BASE_URL + api + "?" + queryParams.toString(), {
                headers: { authorization: this.state.token }
            })
            .then((res) => {
                if (res.data.status === "2000" && res.data.data !== null) {
                    this.setState({ transactions: res.data.data });
                } else {
                    toast.error(res.data.description);
                    this.setState({ transactions: [] });
                }
            })
            .catch((err) => {
                console.log(WEB_BASE_URL + " " + err);
                toast.error("Error fetching transactions");
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }
    

    getDailyTransactions = () => {
        let api = "";
        const today = new Date();
        let datee = this.makeShortDate(today);
        let yesterday = new Date(today);
        yesterday = this.makeShortDate(yesterday.setDate(today.getDate() - 1))
        if (this.state.userGroup == "MERCHANT") {
            api = "/merchant/merchant-custom-report";
        } else {
            api = "/dealer/dealer-custom-report";
        }

        axios
            .get(WEB_BASE_URL
                 + api + 
                 "?startDate=" +
                datee.split('/').join('-') +
                "&endDate=" +
                datee.split('/').join('-') + "&offSet=0&page=1000", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.status == "4000") {
                }
                else {
                    const trans = res.data.data;
                    const filteredTransactions = trans.filter(transaction => 
                        (transaction.customerReference + transaction.requestType + transaction?.beneficiary).includes(this.state.filterValue)
                    );
                    this.setState({ transactions: filteredTransactions });
                    this.reduceTransactions(filteredTransactions);
                }
            })
            .catch((err) => {
                toast.error("Error fetching transactions");
                console.log(err)
            });
    };
    

    getWeeklyTransactions = () => {
        let api = "";

        if (this.state.userGroup == "MERCHANT") {
            api = "/merchant/merchant-weekly-report";
        } else {
            api = "/dealer/dealer-weekly-report";
        }

        axios
            .get(WEB_BASE_URL + api, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.description === "No data available") {
                }
                else {
                    const trans = res.data.data.data;
                    this.setState({ transactions: trans });
                    this.reduceTransactions(trans);
                }
            })
            .catch((err) => {
                toast.error("Error fetching transactions");
            });
    };


    getWalletBalance() {
        axios
            .get(WEB_BASE_URL + "/onboarding/account-balance", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                cookie.save("walletBalance", res.data.data.entBalance, {
                    path: "/",
                    expires: new Date(Date.now() + 60 * 30 * 1000),
                });

                this.setState({ walletBalance: res.data.data.entBalance ? res.data.data.entBalance : 0 });
            })
            .catch((err) => { console.log(WEB_BASE_URL + " " + err); })
    }

    getMerchant() {
        axios
            .post(WEB_BASE_URL + "/onboarding/all-merchants", {},{
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {

                this.setState({ marchantList: res.data.data.data });
            })
            .catch((err) => { console.log(WEB_BASE_URL + " " + err); })
    }

    reduceTransactions(transactions) {
        const dataByDate = transactions.reduce((accumulator, transaction) => {
            const date = transaction.requestDate.split("T")[0];
            const amount = transaction.amount;
            if (!accumulator[date]) {
                accumulator[date] = 0;
            }
            accumulator[date] += amount;
            return accumulator;
        }, {});
        this.setState({ dataByDate });
    }
    handleInputChange = (event) => {
        const { value } = event.target;
        this.setState({ filterValue: value }, () => {
            this.getDailyTransactions(); // Call API again after state update
        });
    };
    componentDidMount() {
        this.getDailyTransactions();
        this.getWalletBalance();
        this.getMerchant();
    }
    handleSelectChange = (event) => {
        const selectedBusinessEmail = event.target.value;
          this.setState({
            merchantEmail: selectedBusinessEmail
          });
      };
      getLast20Chars = (str) => {
        if (!str) {
          return '';
        }
        if (str.length <= 40) {
          return str;
        }
        return str.slice(-40);
      }
    render() {
        var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "NGN",
        });

        const pageSize = 50;
        let totalPages = 0;
        if (this.state.transactions.length !== 0) {
            totalPages = Math.ceil(this.state.transactions.length / pageSize);
        }

        const handlePageChange = (page) => {
            this.setState({ currentPage: page });
        }

        let transactionsToDisplay = this.state.transactions.slice((this.state.currentPage - 1) * pageSize, this.state.currentPage * pageSize);
        return (
            <main className="page-content">
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;">
                                        <i className="bx bx-home-alt"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Transaction
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary">
                                Wallet Balance: &#8358;{this.state.walletBalance}
                            </button>
                        </div>
                    </div>
                </div>
 
                <div className="card">
                    <div className="card-body">
                        <form className="row">
                            <div className="row">
                                <div className="col-3">
                                    <label className="form-label">Date:</label>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onSelect={(date) => this.setStartDate(date)}
                                        onChange={(date) => this.setStartDate(date)}
                                        minDate={new Date(new Date().setDate(new Date().getDate() - 6))}
                                        maxDate={new Date()}
                                    />
                                </div>
                                { this.state.userGroup !== "MERCHANT" &&
                                    <div className="col-3">
                                    <label className="form-label">Select merchant:</label>
                                    {/* <DatePicker
                                        selected={this.state.endDate}
                                        onSelect={(date) => this.setEndDate(date)}
                                        onChange={(date) => this.setEndDate(date)}
                                        minDate={new Date(new Date().setDate(new Date().getDate() - 2))}
                                        maxDate={new Date()}
                                    /> */}
                                    <select
                                     className="form-select "
                                     required
                                     aria-label="Default select example"
                                     onChange={this.handleSelectChange}
                                    >
                                     <option value="">Select marchant</option>
                                     {this.state.marchantList.map((e) =>{
                                        return <option value={e.businessEmail} key={e.id}>{e.businessName}</option>
                                     })}
                                    </select>
                                </div>
                                }
                               
                                <div className="col-3">
                                    <label className="form-label">Transaction Status</label>
                                    <select
                                        className="form-select "
                                        aria-label="Default select example"
                                        value={this.state.status}
                                        onChange={(event) => {
                                            this.setState({
                                                status: event.target.value,
                                            })
                                        }}>
                                        <option value="">All</option>
                                        <option value="200">Success</option>
                                        <option value="5010">Failed</option>
                                    </select>
                                </div>
                                <div className="col-3">
                                    <label className="form-label">Filter</label>
                                    <Multiselect
                                        options={this.state.filterOptions} // Options to display in the dropdown
                                        selectedValues={this.state.selectedFilters} // Preselected value to persist in dropdown
                                        onSelect={this.onSelectFilter} // Function will trigger on select event
                                        onRemove={this.onRemoveFilter} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                        style={{
                                            chips: {
                                                borderRadius: "3px",
                                                backgroundColor: "#6c757d",
                                            }
                                        }}
                                        customCloseIcon={
                                            <i className="bi bi-x-circle-fill" style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "12px"
                                            }}></i>
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                {
                                   
                                    <div className="col-3">
                                        <label className="form-label">Network</label>
                                        <select
                                            className="form-select "
                                            aria-label="Default select example"
                                            value={this.props.formRole}
                                            onChange={(event) => {
                                                this.setState({
                                                    selectedNetwork: event.target.value
                                                })
                                            }}
                                        >
                                         <option  value={''}>Select network</option>
                                            {
                                                this.state.network.map((network, index) => (
                                                    <option key={index} value={network.id}>{network.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                }
                                {/* {
                                    this.state.selectedFilters.find(filter => filter.name === "MSISDN") &&
                                    <div className="col-3">
                                        <label className="form-label">MSISDN</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="MSISDN"
                                            value={this.state.msisdn}
                                            onChange={(event) => {
                                                this.setState({
                                                    msisdn: event.target.value
                                                })
                                            }}
                                        />

                                    </div>
                                } */}
                                {
                                    this.state.selectedFilters.find(filter => filter.name === "Beneficiary No") &&
                                    <div className="col-3">
                                        <label className="form-label">Beneficiary No</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Beneficiary No"
                                            value={this.state.beneficiaryPhone}
                                            onChange={(event) => {
                                                this.setState({
                                                    beneficiaryPhone: event.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    this.state.selectedFilters.find(filter => filter.name === "Merchant ID") &&
                                    <div className="col-3">
                                        <label className="form-label">Merchant ID</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Merchant ID"
                                            value={this.state.merchantId}
                                            onChange={(event) => {
                                                this.setState({
                                                    merchantId: event.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    this.state.selectedFilters.find(filter => filter.name === "Customer Reference") &&
                                    <div className="col-3">
                                        <label className="form-label">Customer Reference</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Customer Reference"
                                            value={this.state.customerReference}
                                            onChange={(event) => {
                                                this.setState({
                                                    customerReference: event.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="col-3 mt-3">
                                    <button
                                        className="btn btn-secondary d-flex"
                                        type="button"
                                        style={{
                                            width: "100%"
                                        }}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.getCustomReport();
                                        }}
                                    >
                                        <div>
                                            <i class="bi bi-arrow-clockwise"></i>
                                        </div>
                                        <div className="mx-1">
                                            {this.state.isLoading ? "Generating..." : "Generate report"}</div>
                                    </button>

                                </div>
                                <div className="col-3 mt-3">
                                    <CSVLink
                                        data={this.state.transactions}
                                        filename={"Topupbox Report.csv"}
                                        target="_blank"
                                    >
                                        <button
                                            className="btn btn-primary d-flex"
                                            type="button"
                                            style={{
                                                width: "100%"
                                            }}
                                        >
                                            <div>
                                                <i class="bi bi-cloud-arrow-down-fill"></i>
                                            </div>
                                            <div className="mx-1">Export report as CSV</div>
                                        </button>
                                    </CSVLink>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header py-3">
                        <div className="row g-3">
                            <div className="col-lg-3 col-6 col-md-3">
                                <select
                                    className="form-select"
                                    value={this.state.selectedTable}
                                    onChange={(event) => {
                                        this.setState({
                                            selectedTable: event.target.value,
                                        });
                                        if (event.target.value === "Daily") {
                                            this.getDailyTransactions();
                                        }
                                        else if (event.target.value === "Weekly") {
                                            this.getWeeklyTransactions();
                                        }
                                        else if (event.target.value === "Monthly") {
                                            this.getTransactions();
                                        }
                                    }}
                                >
                                    <option value="Daily">Daily</option>
                                    {/* <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option> */}
                                </select>
                            </div>
                            <div className="col-lg-6 col-md-6 me-auto">
                                <div className="ms-auto position-relative">
                                    <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                        <i className="bi bi-search"></i>
                                    </div>
                                    <input
                                        className="form-control ps-5"
                                        type="text"
                                        value={this.state.filterValue}
                                        onChange={this.handleInputChange}
                                        placeholder="Search by customer reference, type and beneficiary "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card-body">
                        <div class="table-responsive">
                            <table className="table align-middle mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th>Transaction ID</th>
                                        {this.state.userGroup !== "MERCHANT" &&
                                            <th>Merchant Id</th>                                        }
                                        <th>Beneficiary</th>
                                        <th>Network</th>
                                        <th>Amount</th>
                                        <th>Discount</th>
                                        
                                        {/* <th>Merchant</th> */}
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                        {this.state.userGroup !== "MERCHANT" &&
                                            <th> {this.state.userGroup === "MERCHANT"?"merchant balance":"Network Balance"}</th>
                                        }
                                        <th> Merchant balance</th>
                                        {/* <th>network message</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactionsToDisplay.length !== 0 ? (
                                        transactionsToDisplay.map((voucher, index) => (
                                            <tr key={index}>
                                                <td>{voucher?.customerReference}</td>
                                                {this.state.userGroup !== "MERCHANT" &&
                                               <td>{voucher.accountId}</td>
                                                }
                                                <td>{voucher?.beneficiary}</td>
                                                <td className="d-flex align-items-center gap-3 px-3">
                                                <span className="d-flex align-items-center gap-3 px-3"> {voucher?.networkId == '1'? 'MTN':
                                                        voucher?.networkId == '2'?'Glo':voucher?.networkId == '3'?'9mobile':voucher?.networkId == '4'?'Airtel':'' }</span>
                                                </td>
                                                <td>{formatter.format(voucher.amount)}</td>
                                                <td>{voucher.discountAmount}</td>
                                                
                                                {/* <td>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="product-info">
                                                            <h6 className="product-name mb-1">
                                                                {voucher.accountId}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </td> */}
                                                <td>
                                                    {voucher.requestDate !== undefined
                                                        ? new Date(voucher.requestDate).toLocaleString()
                                                        : 0}
                                                </td>
                                                <td>{voucher.requestType}</td>
                                               
                                                <td>{voucher.statusMessage}</td>
                                                {this.state.userGroup !== "MERCHANT" &&
                                               <td>{formatter.format(voucher.networkBalance)}</td>
                                                }
                                                <td>{formatter.format(voucher.newBalance)}</td>
                                                {/* <td>{voucher.messageFromNetwork}</td> */}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr colSpan="7">
                                            <td>No Transaction Record.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div style={{
                            overflowX: "scroll",
                        }}>
                            <nav class="float-end mt-3">
                                <ul class="pagination">
                                    {Array.from(Array(totalPages), (e, i) => {
                                        let pageNumber = i + 1;
                                        return (
                                            <li class={"page-item " + this.state.currentPage == pageNumber && "active"}>
                                                <a class="page-link" href="/2"
                                                    style={{
                                                        backgroundColor: this.state.currentPage == pageNumber ? "#0d6efd" : "",
                                                        color: this.state.currentPage == pageNumber ? "#fff" : ""
                                                    }}
                                                    onClick={
                                                        (e) => {
                                                            e.preventDefault();
                                                            this.setState({
                                                                currentPage: pageNumber
                                                            })
                                                        }
                                                    }
                                                >
                                                    {pageNumber}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </main >
        );
    }
}

export default Transaction;
