import React from "react";
import axios from "axios";
import { WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    BarElement,
} from 'chart.js';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaymentsIcon from '@mui/icons-material/Payments';
import { BlockLoading } from 'react-loadingg';
import { faker } from '@faker-js/faker';
import { Bar, Line } from 'react-chartjs-2';
class Main extends React.Component {
    constructor() {
        super();
        this.state = {
            token: cookie.load("token"),
            businessName: cookie.load("businessName"),
            walletBalance: 0,
            vasWallet: 0,
            vouchers: [],
            boughtvouchers: [],
            userRole: cookie.load("userRole"),
            userGroup: cookie.load("userGroup"),
            networks: [],
            dailyReport: [],
            transactionCount: {},
            avaliableVouchers: {},
            dataByDate: {},
            isLoading: false,
            total: 0,
            totalTransactionSum:0,
            totalTransactionSuccessful:0,
            totalTransactionFailed:0
        };
        this.getBoughtVouchers = this.getBoughtVouchers.bind(this);
        this.getWalletBalance = this.getWalletBalance.bind(this);
       
        this.getTrasanctionCount = this.getTrasanctionCount.bind(this);
        this.reduceTransactions = this.reduceTransactions.bind(this);
        this.makeShortDate = this.makeShortDate.bind(this);
        this.getCount = this.getCount.bind(this);

        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            BarElement,
            Title,
            Tooltip,
            Legend,
            Filler,
        );
    }


    getWalletBalance() {
        axios
            .get(WEB_BASE_URL + "/onboarding/account-balance", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                cookie.save("walletBalance", res.data.data.entBalance, {
                    path: "/",
                    expires: new Date(Date.now() + 60 * 30 * 1000),
                });
                cookie.save("vasWallet", res.data.data.unicardBalance, {
                    path: "/",
                    expires: new Date(Date.now() + 60 * 30 * 1000),
                });

                this.setState({ walletBalance: res.data.data.entBalance ? res.data.data.entBalance : 0 });
                this.setState({ vasWallet: res.data.data.unicardBalance ? res.data.data.unicardBalance : 0 });
            })
            .catch((err) => { console.log(WEB_BASE_URL + " " + err); })
    }

    makeShortDate(datew) {
        datew = new Date(datew);
        let yy = datew.getFullYear();
        let mm = datew.getMonth();
        let dd = datew.getDate();
        let shortDate = `${yy}/${mm < 10 ? 0 : ""}${mm + 1}/${dd < 10 ? 0 : ""
            }${dd}`;
        return shortDate;
    }

    getBoughtVouchers = () => {
        this.setState({ isLoading: true });
        let api = "";
        const today = new Date();
        let datee = this.makeShortDate(today);
        let yesterday = new Date(today);
        yesterday = this.makeShortDate(yesterday.setDate(today.getDate() - 1))
        console.log(this.state.userGroup)
        if (this.state.userGroup == "MERCHANT") {
            api = "/merchant/merchant-custom-report";
        } else {
            api = "/dealer/dealer-custom-report";
        }
        console.log(WEB_BASE_URL + api + "?startDate=" +
            yesterday.split('/').join('-') +
            "&endDate=" +
            datee.split('/').join('-') + "&offSet=0&page=600&network=&msisdn=&merchantId=&customerRef=&beneficiaryPhone=")

        axios
            .get(WEB_BASE_URL + api + "?startDate=" +
                datee.split('/').join('-') +
                "&endDate=" +
                datee.split('/').join('-') + "&offSet=0&page=600&network=&msisdn=&merchantId=&customerRef=&beneficiaryPhone=", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.description == "No data available") {
                } else {
                    const total = res.data.data.reduce((sum, transaction) => sum + transaction.amount, 0);
                    this.setState({ boughtvouchers: res.data.data, 
                        total: total,
                        // totalTransactionSum: res.data.data.totalTransactionSum,
                        // totalTransactionSuccessful:res.data.data.totalCountSuccessful,
                        // totalTransactionFailed: res.data.data.totalCountFailed,
                    });
                    if (this.state.userGroup === "MERCHANT") {
                        this.setState({ 
                            totalTransactionSum: res.data.data.totalTransactionSum,
                            totalTransactionSuccessful:res.data.data.totalCountSuccessful,
                            totalTransactionFailed: res.data.data.totalCountFailed,
                        });
                    }
                   
                }
            })
            .catch((err) => { console.log(WEB_BASE_URL + " " + err); }).finally(() => {
                this.setState({ isLoading: false });
            });
    };
    getLast20Chars = (str) => {
        if (!str) {
          return '';
        }
        if (str.length <= 40) {
          return str;
        }
        return str.slice(-40);
      }

    getCount = () => {
        this.setState({ isLoading: true });
        let api = "";
        const today = new Date();
        let datee = this.makeShortDate(today);
        let yesterday = new Date(today);
        yesterday = this.makeShortDate(yesterday.setDate(today.getDate() - 1))
        console.log(this.state.userGroup)
        if (this.state.userGroup == "MERCHANT") {
            api = "/merchant/merchant-summarry-report";
        } else {
            api = "/dealer/dealer-summarry-report";
        }
        console.log(WEB_BASE_URL + api + "?startDate=" +
            yesterday.split('/').join('-') +
            "&endDate=" +
            datee.split('/').join('-') + "&offSet=0&page=600")

        axios
            .get(WEB_BASE_URL + api + "?startDate=" +
                datee.split('/').join('-') +
                "&endDate=" +
                datee.split('/').join('-'), {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.description == "No data available") {
                } else {
                    if (this.state.userGroup !== "MERCHANT") {
                    this.setState({
                        totalTransactionSum: res.data.data.totalTransactionSum,
                        totalTransactionSuccessful:res.data.data.totalCountSuccessful,
                        totalTransactionFailed: res.data.data.totalCountFailed,
                    });
                }
                }
            })
            .catch((err) => { console.log(WEB_BASE_URL + " " + err); }).finally(() => {
                this.setState({ isLoading: false });
            });
    };

    //write a function to get the count of total transactions from count-transaction API
    getTrasanctionCount() {
        const today = new Date();
        // Get the components of today's date
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth() + 1; // Months are zero-indexed, so add 1
        const todayDate = today.getDate();

        // Formatted string of today's date (in yyyy-mm-dd format)
        const formattedToday = `${todayYear}-${todayMonth < 10 ? '0' : ''}${todayMonth}-${todayDate < 10 ? '0' : ''}${todayDate}`;

        // Subtract one day (24 hours) from today's date
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        // Get the components of yesterday's date
        const yesterdayYear = yesterday.getFullYear();
        const yesterdayMonth = yesterday.getMonth() + 1; // Months are zero-indexed, so add 1
        const yesterdayDate = yesterday.getDate();

        // Formatted string of yesterday's date (in yyyy-mm-dd format)
        const formattedYesterday = `${yesterdayYear}-${yesterdayMonth < 10 ? '0' : ''}${yesterdayMonth}-${yesterdayDate < 10 ? '0' : ''}${yesterdayDate}`;
        let api = "";
        if (this.state.userGroup == "MERCHANT") {
            api = "/merchant/merchant-dataBoard";
        } else {
            api = "/dealer/dealer-dataBoard";
        }
        axios
            .get(WEB_BASE_URL + api + "?endDate=" + formattedToday
                + "&merchantEmail=&startDate=" + formattedYesterday, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.status === "2000" && res.data.data !== null) {
                    this.setState({ transactionCount: res.data.data });
                }
            })
            .catch((err) => {
                console.log(WEB_BASE_URL + " " + err);
            });
    }



    componentDidMount() {
        const user = cookie.load("user");
        const userRole = cookie.load("userRole");
        if (user !== undefined) {
            this.getWalletBalance();
            this.getBoughtVouchers();
            this.getTrasanctionCount();
            this.getCount();
        }
    }

    reduceTransactions(transactions) {
        const dataByDate = transactions.reverse().reduce((accumulator, transaction) => {
            const date = transaction.requestDate.split("T")[0];
            const amount = transaction.amount;
            if (!accumulator[date]) {
                accumulator[date] = 0;
            }
            accumulator[date] += amount;
            return accumulator;
        }, {});
        this.setState({ dataByDate });
    }

    render() {
        var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "NGN",
        });

        const labels = ["Type of Transaction"];
        const options = {
            responsive: true,
            plugins: [{
                legend: {
                    position: 'top',
                },
                title: {
                    display: false,
                    text: 'Chart.js Line Chart',
                },
            },
            {
                beforeDraw: (chart) => {
                    if (this.state.isLoading) {
                        const canvas = chart.canvas;
                        const ctx = canvas.getContext('2d');
                        const centerX = canvas.width / 2;
                        const centerY = canvas.height / 2;

                        ctx.save();
                        ctx.beginPath();
                        ctx.translate(centerX, centerY);
                        BlockLoading.render({
                            size: 50,
                            color: '#263B95',
                            speed: 1,
                            duration: 2000
                        }, ctx);
                        ctx.restore();
                    }
                }
            }
            ],
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "Transaction Type"
                    },
                },
                y: {
                    grid: {
                        display: false,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "Number of transactions"
                    },
                }
            }
        };
        const data = {
            labels,
            datasets: [
                {
                    label: "All transactions",
                    data: [this.state.totalTransactionSuccessful + this.state.totalTransactionFailed],
                    backgroundColor: '#263B95',
                    borderColor: '#263B95',
                    borderRadius: 14,
                    borderWidth: 2,
                    fill: true, // Add color to the area underneath the line
                },
                {
                    label: "Successful transactions",
                    data: [this.state.totalTransactionSuccessful],
                    backgroundColor: '#76c48b',
                    borderColor: '#76c48b',
                    borderRadius: 14,
                    borderWidth: 2,
                    fill: true, // Add color to the area underneath the line
                },
                {
                    label: "Failed transactions",
                    data: [this.state.totalTransactionFailed],
                    backgroundColor: '#e0365e',
                    borderColor: '#e0365e',
                    borderRadius: 14,
                    borderWidth: 2,
                    fill: true, // Add color to the area underneath the line
                },
            ],
        };
        return (
            <div className="page-content">
                <ToastContainer
                    position="top-center"
                    autoClose={false}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="row px-4  gx-5">
                    <div className="col-md-8 card ">
                        <div className="overflow-hidden radius-10">
                            <div className="card-body align-items-center flex justify-content-between" style={{
                                minHeight: "30vh",
                                maxHeight: "40vh",
                                paddingLeft: "7%"
                            }}>
                                {this.state.isLoading && <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><BlockLoading color="#263B95" /></div>}
                                <Bar options={options} data={data} />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4">

                        <div className="row  g-3 ">
                            <div className="col-md-5  card">
                                <div className=" overflow-hidden radius-10">
                                    <div className="  card-body">
                                        <div className=" align-items-stretch  h-100 justify-content-between overflow-hidden pb-1">
                                            <div className="">
                                               <AccountBalanceWalletIcon size={'xl'}/>
                                                <div className="mb-1 mt-4">VTU Wallet Balance</div>

                                                <h5 className="" style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                }}>
                                                    {formatter.format(this.state.walletBalance)}
                                                </h5>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1 "></div>
                            <div className="col-md-5  card">
                                <div className=" overflow-hidden radius-10">
                                    <div className="card-body">
                                        <div className="align-items-stretch  h-100 justify-content-between overflow-hidden">
                                            <div className="">
                                            <PaymentsIcon/>
                                                <div className="mb-1 mt-4">VAS Wallet Balance</div>
                                                <h5 className="" style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                }}>{formatter.format(this.state.vasWallet)}</h5>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>

                            <div className="row   ">
                            <div className="col-md-5  card ">
                                <div className=" overflow-hidden radius-10">
                                    <div className="card-body">
                                        <div className="align-items-stretch justify-content-between overflow-hidden">
                                            <div className=" mb-2">
                                            <img
                                                    src="assets/images/incoming.png"
                                                    style={{
                                                        height: "28px",
                                                        width: "auto",
                                                        borderRadius: "32px",
                                                        marginBottom: "5px"
                                                    }}
                                                    className="mb-4"
                                                    alt=""
                                                />
                                                <div className="mb-1">Total transactions</div>
                                                <h5 className="" style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                }}>{this.state.totalTransactionSuccessful + this.state.totalTransactionFailed}</h5>
                                               
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div className="col-md-1 "></div>
                            <div className="col-md-5 card">
                                <div className=" overflow-hidden radius-10">
                                    <div className="card-body">
                                        <div className="align-items-stretch justify-content-between overflow-hidden">
                                            <div className="">
                                                <img
                                                    src="assets/images/valid.png"
                                                    style={{
                                                        height: "28px",
                                                        width: "auto",
                                                        borderRadius: "32px",
                                                        marginBottom: "5px"
                                                    }}
                                                    alt=""
                                                    className="mb-4"
                                                />
                                                <div className="mb-1">Total Transaction Value</div>
                                                <h5 className="" style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                }}>{formatter.format(this.state.totalTransactionSum)}</h5>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-12 col-xl-12 d-flex">
                        <div className="card radius-10 w-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h6 className="mb-0">Recent transactions </h6>
                                    <div className="fs-5 ms-auto dropdown">
                                        <div
                                            className="dropdown-toggle dropdown-toggle-nocaret cursor-pointer"
                                            data-bs-toggle="dropdown"
                                        >
                                            <i className="bi bi-three-dots"></i>
                                        </div>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item" href="/transactions">
                                                    See All
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="table-responsive mt-2">
                                    <table className="table align-middle mb-0">
                                    <thead className="table-light">
                                    <tr>
                                        <th>Transaction ID</th>
                                        {this.state.userGroup !== "MERCHANT" &&
                                            <th>Merchant Id</th>                                        }
                                        <th>Beneficiary</th>
                                        <th>Network</th>
                                        <th>Amount</th>
                                        <th>Discount</th>
                                        
                                        {/* <th>Merchant</th> */}
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                        {this.state.userGroup !== "MERCHANT" &&
                                            <th> {this.state.userGroup === "MERCHANT"?"merchant balance":"Network Balance"}</th>
                                        }
                                        <th> Merchant balance</th>
                                        {/* <th>network message</th> */}
                                    </tr>
                                      </thead>
                                        <tbody>
                                            {this.state.boughtvouchers.length !== 0 ? (
                                                this.state.boughtvouchers.slice(0, 10).map((voucher, index) => (
                                                    <tr key={index}>
                                                <td>{voucher?.customerReference}</td>
                                                {this.state.userGroup !== "MERCHANT" &&
                                               <td>{voucher.accountId}</td>
                                                }
                                                <td>{voucher?.beneficiary}</td>
                                                <td className="d-flex align-items-center gap-3 px-3">
                                                <span className="d-flex align-items-center gap-3 px-3"> {voucher?.networkId == '1'? 'MTN':
                                                        voucher?.networkId == '2'?'Glo':voucher?.networkId == '3'?'9mobile':voucher?.networkId == '4'?'Airtel':'' }</span>
                                                </td>
                                                <td>{formatter.format(voucher.amount)}</td>
                                                <td>{voucher.discountAmount}</td>
                                                
                                                {/* <td>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="product-info">
                                                            <h6 className="product-name mb-1">
                                                                {voucher.accountId}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </td> */}

                                                {/* //jjd */}
                                                <td>
                                                    {voucher.requestDate !== undefined
                                                        ? new Date(voucher.requestDate).toLocaleString()
                                                        : 0}
                                                </td>
                                                <td>{voucher.requestType}</td>
                                               
                                                <td>{voucher.statusMessage}</td>
                                                {this.state.userGroup !== "MERCHANT" &&
                                               <td>{formatter.format(voucher.networkBalance)}</td>
                                                }
                                                <td>{formatter.format(voucher.newBalance)}</td>
                                                {/* <td>{voucher.messageFromNetwork}</td> */}
                                            </tr>        ))
                                            ) : (
                                                <tr colSpan="7">
                                                    <td>No Transaction Record.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default Main;
